<template>
    <FullscreenImg
        v-bind:imgData="currentImg"
        v-on:resetImgData="currentImg=null"
        v-on:reloadImgData="resetList"
        v-on:openImg="opennewImg($event)"
    />
    <h1>
        <span class="material-icons-outlined">
        photo_album
        </span>
        <span>
            {{albumTitle}}
        </span>
        <div class="uploadIconOuther">
            <form id="uploadForm">
                <label for="file">
                    <button type="button" class="material-icons-outlined uploadIcon" onclick="document.getElementById('file').click()">
                        add_a_photo
                    </button>
                    <input type="file" id="file" v-on:change="triggerUpload" multiple>
                </label>
            </form>
            <button type="button" class="material-icons-outlined uploadIcon" v-on:click="exportAlbum()">
                send_to_mobile
            </button>
        </div>
    </h1>
    <p class="error">
        {{error}}
    </p>
    <div class="frontContent"></div>
</template>

<script>
import axios from 'axios'
import FullscreenImg from '../components/FullscreenImg.vue'

export default {
    created () {
        if(!this.$route.params.albumId.match(/^[0-9]{1,4}$/gm)){
            this.$router.push('/album');
        }else{
            this.oncreated();
        }

        setTimeout(()=>{
            //generate images => get image list

            let formData = new FormData;
            formData.append('id', this.$route.params.albumId);
            axios.post('/api/v1/album/list/imgs/?count='+this.counter, formData)
            .then(response => {
                this.counter++;
                this.imgs.push(...response.data);
                // compute images
                setTimeout(()=>{
                    this.resized();
                }, 500);
            })
            .catch(error => {
                console.log(error);
            });
        }, 500);

        window.addEventListener('resize', this.resized);
        document.body.addEventListener('scroll', this.repositioned);
    },
    beforeUnmount(){
        window.removeEventListener('resize', this.resized);
        document.body.removeEventListener('scroll', this.repositioned);
    },
    methods:  {
        oncreated(){
            //album info
            let formData = new FormData;
            formData.append('id', this.$route.params.albumId);

            axios.post('/api/v1/album/info/', formData)
            .then(response => {
                if(response.data.success === true){
                    this.albumTitle = response.data.value.title;
                }else{
                    if(response.data.error){
                        this.error = response.data.error;
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
        },
        getImage(src){
            return new Promise(resolve => {
                const img = new Image();
                img.onload = function() {
                    resolve({width: img.width, height: img.height, src: src});
                }
                img.src = src;
            });
        },
        computeList(){
            //recreate colums
            let container = document.querySelector('.frontContent');
            container.innerHTML = '';

            for(let i = 0; i < this.columns; i++){
                container.innerHTML += `<div class='column'></div>`
            }
            //set sizing
            document.querySelectorAll('.column').forEach(column => {
                column.style.width = `calc(100% / ${this.columns})`;
            });
            //read and insert pictures
            this.imgs.forEach(async img => {
                let columns = document.querySelectorAll('.column');
                let smallest = 0;
                for(let i = 0; i < this.columns; i++){
                    let column = columns[i];
                    let height = column.offsetHeight;
                    if(columns[smallest].offsetHeight > height){
                        smallest = i;
                    }
                }
                let img_obj = document.createElement('img');
                img_obj.setAttribute('data-src', img.img1_p);
                img_obj.setAttribute('get-info', JSON.stringify(img));
                img_obj.setAttribute('class', 'lazy listImage');
                img_obj.setAttribute('onload', 'this.style.opacity = 1;this.style.clipPath = "inset(0 0 0% 0)";');
                img_obj.setAttribute('style', `height: calc(100vh / ${this.columns} * (${Math.round(img.width / 100) * 100} / ${Math.round(img.height / 100) * 100}))`);
                columns[smallest].appendChild(img_obj);
                // document.querySelectorAll('.listImage')[document.querySelectorAll('.listImage').length-1].setAttribute('get-count', document.querySelectorAll('.listImage').length-1);
            });

            //override count images
            let DomImgs = document.querySelectorAll('.listImage');
            let countDom = 0;
            DomImgs.forEach(DomImg => {
                DomImg.setAttribute('get-count', countDom);
                countDom++;
            });

            let allDomImgs = document.querySelectorAll('.listImage');
            allDomImgs.forEach(DomImg => {
                DomImg.addEventListener('click', ()=>{
                    let info = JSON.parse(DomImg.getAttribute('get-info'));
                    this.currentImg = {
                        id: DomImg.getAttribute('get-id'),
                        img0_p: info.img0_p,
                        img1_p: info.img1_p,
                        img2_p: info.img2_p,
                        img3_p: info.img3_p,
                        info: info,
                        currentPos: DomImg.getAttribute('get-count'),
                    };
                });
            });
        },
        appendToList(newImgs){
            newImgs.forEach(async img => {
                let columns = document.querySelectorAll('.column');
                let smallest = 0;
                for(let i = 0; i < this.columns; i++){
                    let column = columns[i];
                    let height = column.offsetHeight;
                    if(columns[smallest].offsetHeight > height){
                        smallest = i;
                    }
                }
                let img_obj = document.createElement('img');
                img_obj.setAttribute('data-src', img.img1_p);
                img_obj.setAttribute('get-info', JSON.stringify(img));
                img_obj.setAttribute('class', 'lazy listImage');
                img_obj.setAttribute('onload', 'this.style.opacity = 1;this.style.clipPath = "inset(0 0 0% 0)";');
                img_obj.setAttribute('style', `height: calc(100vh / ${this.columns} * (${Math.round(img.width / 100) * 100} / ${Math.round(img.height / 100) * 100}))`);
                columns[smallest].appendChild(img_obj);
                // document.querySelectorAll('.listImage')[document.querySelectorAll('.listImage').length-1].setAttribute('get-count', document.querySelectorAll('.listImage').length-1);
            });

            //override count images
            let DomImgs = document.querySelectorAll('.listImage');
            let countDom = 0;
            DomImgs.forEach(DomImg => {
                DomImg.setAttribute('get-count', countDom);
                countDom++;
            });

            let allDomImgs = document.querySelectorAll('.listImage');
            allDomImgs.forEach(DomImg => {
                DomImg.addEventListener('click', ()=>{
                    let info = JSON.parse(DomImg.getAttribute('get-info'));
                    this.currentImg = {
                        id: DomImg.getAttribute('get-id'),
                        img0_p: info.img0_p,
                        img1_p: info.img1_p,
                        img2_p: info.img2_p,
                        img3_p: info.img3_p,
                        info: info,
                        currentPos: DomImg.getAttribute('get-count'),
                    };
                });
            });

            setTimeout(()=>{
                console.log(`----ended`)
                this.computing = false;
            }, 500);
        },
        resized(){
            let columnCount = document.querySelectorAll('.column').length;
            //check screen sizing
            if(window.innerWidth > 1200){
                if(this.columns === 3 && columnCount === 3){
                    return;
                }else{
                    this.columns = 3;
                }
            }
            if(window.innerWidth < 900){
                if(this.columns === 1 && columnCount === 1){
                    return;
                }else{
                    this.columns = 1;
                }
            }else{
                if(window.innerWidth < 1200){
                    if(this.columns === 2 && columnCount === 2){
                        return;
                    }else{
                        this.columns = 2;
                    }
                }
            }

            // define & compute
            this.computeList();
        },
        repositioned(){
            if(this.computing === true || this.hasMoreImgs === false){
                return;
            }
            this.computing = true;
            let el = document.body;
            let dif = el.scrollHeight - (el.scrollTop + window.innerHeight);
            if(dif < 3000){
                let formData = new FormData;
                formData.append('id', this.$route.params.albumId);
                axios.post('/api/v1/album/list/imgs/?count='+this.counter, formData)
                .then(response => {
                    this.counter++;
                    this.imgs.push(...response.data);
                    this.appendToList(response.data);
                    if(response.data.length==0){
                        this.counter--;
                        this.hasMoreImgs = false;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            }else{
                this.computing = false;
            }
        },
        triggerUpload(){
            this.uploadImg.push(...document.querySelector('#file').files);
            document.querySelector('#uploadForm').reset();
            this.uploadFromArray();
        },
        async uploadFromArray(){
            if(this.isUploading){
                return;
            }
            this.isUploading = true;
            let albumId = this.$route.params.albumId;
            
            let loadingBar = document.querySelector('.loadingBar');
            loadingBar.style.transitionDuration = '1s';
            loadingBar.style.width = '0%';

            for(let i = 0; i < this.uploadImg.length; i++){
                let img = this.uploadImg[i];
                let formData = new FormData;
                formData.append('file', img);
                formData.append('id', albumId);

                // console.log(`loading:${100/this.uploadImg.length*i} length:${this.uploadImg.length} i:${i+1}`)

                let upres = await this.directUpload(formData, this.uploadImg.length, i, loadingBar);
                console.log(`Success: ${upres}`);
            }
            
            this.uploadImg = [];

            // compute images
            setTimeout(()=>{
                loadingBar.style.transitionDuration = '0s';
                loadingBar.style.width = '0%';
                setTimeout(()=>loadingBar.style.transitionDuration = '0s', 200);
                this.isUploading = false;

                this.resetList();
            }, 500);
        },
        directUpload(formData, length, i, loadingBar){
            return new Promise(resolve => {
                // loadingBar.style.width = `${100/length*(i+1)-(((100/length*(i))-(100/length*(i+1)))/2)}%`;
                axios.post('/api/v1/album/upload/', formData)
                .then(response => {
                    loadingBar.style.width = `${100/length*(i+1)}%`;
                    if(response.data.success){
                        console.log('Successfull uploaded');
                    }else{
                        alert(
                            (response.data.error == null)?'Unbekannter Error: '+JSON.stringify(response.data):response.data.error
                        );
                    }
                    resolve(true);
                })
                .catch(error => {
                    console.log(error);
                    resolve(false);
                });
            });
        },
        resetList(){
            this.hasMoreImgs = true;
            this.counter = 1;
            setTimeout(()=>{
                let formData = new FormData;
                formData.append('id', this.$route.params.albumId);
                axios.post('/api/v1/album/list/imgs/?count='+this.counter, formData)
                .then(response => {
                    this.counter++;
                    this.imgs = response.data;
                })
                .catch(error => {
                    console.log(error);
                });
                
                // compute images
                setTimeout(()=>{
                    this.computeList();
                }, 100);
            }, 500);
        },
        opennewImg(i){
            console.log('catch emit: '+i)
            this.currentImg = null;
            
            if(typeof document.querySelectorAll('.listImage')[i] == 'undefined'){
                    console.log('not found image');
                    return;
                }
                let DomImgs = document.querySelectorAll('.listImage');
                let countDom = 0;
                DomImgs.forEach(DomImg => {
                    if(countDom == i){
                        let info = JSON.parse(DomImg.getAttribute('get-info'));
                        this.currentImg = {
                            id: DomImg.getAttribute('get-id'),
                            img0_p: info.img0_p,
                            img1_p: info.img1_p,
                            img2_p: info.img2_p,
                            img3_p: info.img3_p,
                            info: info,
                            currentPos: DomImg.getAttribute('get-count'),
                        };
                    }
                    countDom++;
                });
        },
        exportAlbum(){
            if(confirm('Alle Bilder dieses Albums werden dir per Email geschickt.\nBei grossen Mengen von Bilder kann dieser Prozess ein paar Minuten dauern.\nBist du sicher, dass du fortfahren möchtest?')){
                let formData = new FormData;
                formData.append('id', this.$route.params.albumId);
                axios.post('/api/v1/album/export/', formData)
                .then(response => {
                    if(response.data.success){
                        alert('Überprüfe dein Email Postfach. Die Zip Datei wurde erfolgreich versendet.');
                    }else{
                        alert(JSON.stringify(response));
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            }
        }
    },
    components: {
        FullscreenImg: FullscreenImg
    },
    data(){
        return {
            albumTitle: 'Album',
            error: '',
            imgs: [],
            columns: 0,
            counter: 1,
            computing: false,
            uploadImg: [],
            isUploading: false,
            hasMoreImgs: true,
            currentImg: null
        }
    },
}
</script>

<style scoped>
    h1{
        display: flex;
        align-items: center;
        white-space: nowrap;
    }
    h1 span{
        margin: 0px 10px;
    }
    .error{
        color: red;
    }
    #file{
        pointer-events: none;
        opacity: 0;
        width: 1px;
        height: 1px;
        overflow: hidden;
    }
    .uploadIconOuther{
        display: flex;
        margin-left: auto;
    }
    .uploadIcon{
        pointer-events: all;
        cursor: pointer;
    }
    #uploadForm{
        display: inline;
    }
</style>
<style>
    .frontContent{
        display: block;
    }
    .column{
        float: left;
        width: calc(100% / 3);
    }
    .column > img{
        width: 100%;
        max-height: 80vh !important;
        min-height: 320px;
        object-position: center;
        opacity: 0;
        transition: .3s ease;
        clip-path: inset(0 0 100% 0);
        cursor: pointer;
        padding: 3px 4px;
    }
    .column > img:hover{
        opacity: .4 !important;
    }
</style>